import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Data from "../policies.json";

function Policy() {
  const { type } = useParams();
  const policyData = Data[type];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type]);

  const renderData = (data) => {
    if (typeof data === "string") {
      return <p>{data}</p>;
    } else if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index} className="policy-items">
              {renderData(item)}
            </li>
          ))}
        </ul>
      );
    } else if (typeof data === "object") {
      return (
        <ul>
          {Object.entries(data).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {renderData(value)}
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <section className="about-section">
      <div className="overlay pt-120 pb-120">
        <div className="container wow fadeInUp">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="text-area">
                <h2 className="title">{policyData.title} </h2>
                <p>{policyData.lead}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">{renderData(policyData.content)}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Policy;
