import React, { useEffect } from "react";

function Services() {
  const heading = "Services We Offer";
  const data = [
    {
      title: "Mobile Recharge",
      lead: "Flat ₹10 CB",
      image: "/images/service/mobile_2.png",
    },
    {
      title: "Mobile Postpaid",
      lead: "Upto ₹30 CB",
      image: "/images/service/postpaid_2.svg",
    },
    {
      title: "Rent via Credit Card",
      lead: "",
      image: "/images/service/rentals_2.png",
    },
    {
      title: "Electricity",
      lead: "Upto ₹30 CB",
      image: "/images/service/electricity_2.png",
    },
    {
      title: "DTH",
      lead: "Upto ₹20 CB",
      image: "/images/service/dth_2.png",
    },
    {
      title: "FASTag Recharge",
      lead: "",
      image: "/images/service/fastag_2.svg",
    },
    {
      title: "Book a Cylinder",
      lead: "",
      image: "/images/service/cylinder-booking_2.png",
    },
    {
      title: "Landline",
      lead: "",
      image: "/images/service/landline_2.png",
    },
    {
      title: "Gas",
      lead: "",
      image: "/images/service/gas_2.svg",
    },
    {
      title: "Pay Credit Card Bill",
      lead: "",
      image: "/images/service/cc_2.svg",
    },
    {
      title: "Water",
      lead: "",
      image: "/images/service/water_2.png",
    },
    {
      title: "Broadband",
      lead: "",
      image: "/images/service/broadband_2.png",
    },
    {
      title: "OTT/Subscriptions",
      lead: "",
      image: "/images/service/subscription_2.svg",
    },
    {
      title: "Loan Repayment",
      lead: "",
      image: "/images/service/loan-payment_2.svg",
    },
    {
      title: "Google Play",
      lead: "",
      image: "/images/service/google_play_2 (1).webp",
    },
    {
      title: "Insurance",
      lead: "",
      image: "/images/service/insurance_2.png",
    },
    {
      title: "Municipal Tax",
      lead: "",
      image: "/images/service/municipal_2.png",
    },
    {
      title: "Apartments",
      lead: "",
      image: "/images/service/housing-society_2.svg",
    },
    {
      title: "Cable TV",
      lead: "",
      image: "/images/service/cable-tv-recharge_2.svg",
    },
    {
      title: "Education Fees",
      lead: "",
      image: "/images/service/education-fees_2.svg",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section className="services" style={{ marginTop: "5em" }}>
        <div className="container servicesBlock">
          <div className="servicesBlock__title">
            <h1 className="size36 text-center" style={{ color: "#051441" }}>
              <strong>{heading}</strong>
            </h1>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="main-content">
              <div className="home-tab-wrapper">
                <div className="grid-section">
                  <ul className="section-tiles-wrapper section-expanded d-flex flex-wrap justify-content-center">
                    {data.map((item, index) => (
                      <li
                        className="section-category-tile col-6 col-sm-4 col-md-3 col-lg-2"
                        title={item.title}
                        key={index}
                      >
                        <div className="tile-img-tag">
                          <img
                            src={item.image}
                            alt={item.title}
                            className="tile-img"
                          />
                        </div>
                        <div className="tile-type">{item.title}</div>
                        <div className="tile-cb">{item.lead}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Services;
