import React, { useEffect } from "react";
import PageBanner from "../components/PageBanner";

function Contact() {
  const heading = "Need More Help?";
  const subHeading = "You can reach out to us for all your";
  const leadText =
    "Queries, complaints and feedback. We will be happy to serve you";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <PageBanner
        heading="Contact Us"
        classNameName="contact"
        parentComponent="Home"
        parentPath="/"
      />

      <section className="account-feature loan-feature need-more-help">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-header text-center">
                  <h5 className="sub-title">{subHeading}</h5>
                  <h2 className="title">{heading}</h2>
                  <p>{leadText}</p>
                </div>
              </div>
            </div>
            <div
              className="row cus-mar"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="col-md-6">
                <div className="single-box">
                  <div className="icon-box">
                    <img src="/images/icon/need-help-2.png" alt="icon" />
                  </div>
                  <h4>Help & Support</h4>
                  <p>
                    <span className="text-dark">support@payboom.in</span>
                  </p>
                  <p>
                    <span className="text-dark">+919764568209</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Contact;
